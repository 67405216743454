import React, { useMemo, useState } from 'react';
import { IQuoteDetail } from './quote.interface';
import { Box, Chip, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { IJustificationText, IJustificationTextPart } from './justification-text.interface';
import { uniq } from 'lodash';

type Props = {
  quote: IQuoteDetail;
};

export const QuoteRecommendationTexts: React.FC<Props> = ({ quote }) => {
  const slugs = useMemo(() => uniqueSlugs(quote.justificationTexts), [quote.justificationTexts]);
  const [activeSlug, setActiveSlug] = useState<string | null>(null);

  if (quote.justificationTexts.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" component="h2">
        Justification Texts
      </Typography>
      <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
        {[null, ...slugs].map((slug) => (
          <Chip
            key={slug}
            label={slug === null ? 'All' : slug}
            color={activeSlug === slug ? 'primary' : 'default'}
            icon={activeSlug === slug ? <CheckIcon sx={{ fontSize: '16px' }} /> : undefined}
            onClick={(): void => setActiveSlug(slug)}
          />
        ))}
      </Stack>
      {quote.justificationTexts.map(({ productName, texts }, index) => (
        <Stack direction="row" key={`quote-recommendation-text-${index}`} sx={{ mt: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', minWidth: '170px' }}>
            {productName}
          </Typography>
          <Typography variant="body1" sx={{ flexGrow: 1, ml: 1, whiteSpace: 'pre-line' }}>
            {printTexts(texts, activeSlug)}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};

function uniqueSlugs(justificationTexts: IJustificationText[]): string[] {
  return uniq(
    justificationTexts.map(({ texts }) => texts.map(({ slug }) => slug).filter(Boolean)).flat(),
  ).sort() as string[];
}

function printTexts(texts: IJustificationTextPart[], activeSlug: string | null): string {
  return texts
    .filter(({ slug }) => (activeSlug !== null ? slug === activeSlug : true))
    .map(({ text, title }) => `[${title}] ${text}`)
    .join('\n\n');
}
